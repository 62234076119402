import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { AnchorLink } from "gatsby-plugin-anchor-links"

function Header(props) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <nav id={props.id} className="flex items-center w-full bg-white shadow">
      <div className="container mx-auto">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
            <div className="flex w-full space-between">
              <div className="lg:w-0 lg:flex-1">
                <Link
                  to="/"
                  className="block mr-4 font-semibold smooth lg:inline-block lg:mt-0 hover:-light"
                >
                  <StaticQuery
                    query={graphql`
                      query HeadingImageQuery {
                        file(relativePath: { eq: "logo-header.jpg" }) {
                          childImageSharp {
                            fluid(maxWidth: 300) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                      }
                    `}
                    render={data => (
                      <div className="flex items-center w-32 lg:w-64">
                        <Img
                          className="w-48"
                          fluid={data.file.childImageSharp.fluid}
                        />
                      </div>
                    )}
                  />
                </Link>
              </div>
              <div className="items-center hidden lg:flex space-x-10">
                <h2 className="mb-3">
                  <AnchorLink
                    to="/#powerOfSport"
                    className="inline-flex items-center text-lg font-normal hover:-light md:px-1 leading-5 focus:outline-none transition duration-150 ease-in-out"
                  >
                    Power of Sport
                  </AnchorLink>
                </h2>
                <h2 className="mb-3">
                  <AnchorLink
                    to="/#benefitsOfSport"
                    className="inline-flex items-center text-lg font-normal hover:-light md:px-1 leading-5 focus:outline-none transition duration-150 ease-in-out"
                  >
                    Benefits of Sport
                  </AnchorLink>
                </h2>
                <h2 className="mb-3">
                  <AnchorLink
                    to="/#help"
                    className="inline-flex items-center text-lg font-normal hover:-light md:px-1 leading-5 focus:outline-none transition duration-150 ease-in-out"
                  >
                    How Can We Help?
                  </AnchorLink>
                </h2>
                <h2 className="mb-3">
                  <AnchorLink
                    to="/#contact"
                    className="inline-flex items-center text-lg font-normal hover:-light md:px-1 leading-5 focus:outline-none transition duration-150 ease-in-out"
                  >
                    Contact
                  </AnchorLink>
                </h2>
              </div>
            </div>
            <div className="flex items-center -mr-2 lg:hidden">
              {/* Mobile menu button */}
              <button
                onClick={() => toggleExpansion(!isExpanded)}
                className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                aria-label="Main menu"
                aria-expanded="false"
              >
                {/* Icon when menu is closed. */}
                {/* Menu open: "hidden", Menu closed: "block" */}
                <svg
                  className="block w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* Icon when menu is open. */}
                {/* Menu open: "block", Menu closed: "hidden" */}
                <svg
                  className="hidden w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/*
          Mobile menu, toggle classNames based on menu state.

          Menu open: "block", Menu closed: "hidden"
        */}
        <div className={`${isExpanded ? `block` : `hidden`} block md:hidden`}>
          <div className="pt-2 pb-3 text-center">
            <Link
              to="/#powerOfSport"
              className="block py-2 pl-3 pr-4 text-base font-medium hover:-light focus:outline-none transition duration-150 ease-in-out"
            >
              <h6 className="text-base font-normal">Power of Sport</h6>
            </Link>
            <Link
              to="/#smarterTogether"
              className="block py-2 pl-3 pr-4 text-base font-medium hover:-light focus:outline-none transition duration-150 ease-in-out"
            >
              <h6 className="text-base font-normal">Benefits of Sport</h6>
            </Link>
            <Link
              to="/#valueAsAService"
              className="block py-2 pl-3 pr-4 text-base font-medium hover:-light focus:outline-none transition duration-150 ease-in-out"
            >
              <h6 className="text-base font-normal">How Can We Help?</h6>
            </Link>
            <Link
              to="/#perspectives"
              className="block py-2 pl-3 pr-4 text-base font-medium hover:-light focus:outline-none transition duration-150 ease-in-out"
            >
              <h6 className="text-base font-normal">Contact</h6>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
