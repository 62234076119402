import React from "react"

const Footer = () => {
  return (
    <footer id="contact" className="p-12 text-center">
      <h2 className="text-xl text-blue-500">Contact Us</h2>
      <p className="text-sm">
        Copyright &copy; {new Date().getFullYear()} Inspired Sports
      </p>
    </footer>
  )
}

export default Footer
